import Navbar, {NavbarBody, NavbarHeader, NavbarIcon, NavbarItem, NavbarList, NavbarUserIcon} from "../../Navbar";
import React, {useMemo, useState} from "react";
import {
    PiAddressBook,
    PiBell,
    PiBuildings,
    PiCalendar, PiDoorOpen,
    PiEnvelope,
    PiGear,
    PiGlobe,
    PiMegaphoneSimple,
    PiSignOut,
    PiTicket, PiUserList,
    PiUsers,
    PiUsersFour
} from "react-icons/pi";
import {
    Box,
    Flex,
} from "@chakra-ui/react";
import {IconType} from "react-icons";
import DefaultLayout from "../../DefaultLayout";
import {useUserPermission} from "../../../hooks/userPermissions";

export default function LoggedInLayout({
    children,
    loggedUser,
    onLogOut,
    activeMenu = null,
    updatedMenu = null,
    isSuperAdminLogged = false,
    hasNotification = false,
}: {
    children: React.ReactNode,
    loggedUser: any,
    onLogOut: (e: Event) => void,
    activeMenu?: string,
    updatedMenu?: string,
    isSuperAdminLogged: boolean,
    hasNotification?: boolean,
}) {
    const layoutParameters = useMemo(() => {
        const initialMenu = {
            topMenu: [
                {key: "company", icon: PiBuildings, label: "Sociétés", isUpdated: false, isActive: false, href: "/societe", isNavbarItemDisabled: false},
                {key: "contacts", icon: PiAddressBook, label: "Contacts", isUpdated: false, isActive: false, href: "/contact", isNavbarItemDisabled: false},
                {key: "events", icon: PiCalendar, label: "Événements", isUpdated: false, isActive: false, href:"/evenement", isNavbarItemDisabled: false},
                {key: "mail_campaigns", icon: PiEnvelope, href: "/campagne-mail", label: "Campagnes de mails", isUpdated: false, isActive: false, isNavbarItemDisabled: false},
                {key: "payments", icon: PiTicket, href: "/cotisation", label: "Cotisations", isUpdated: false, isActive: false, isNavbarItemDisabled: false},
                {key: "surveys", icon: PiMegaphoneSimple, label: "Enquêtes", href: "/enquete", isUpdated: false, isActive: false, isNavbarItemDisabled: false},
                {key: "dashboard", icon: PiGlobe, label: "Tableau de bord", isUpdated: false, isActive: false, isNavbarItemDisabled: true},
                {key: "groups_sections", icon: PiUsersFour, label: "Collèges / Métiers", isUpdated: false, isActive: false, href: "/metier-college", isNavbarItemDisabled: false},
                {key: "users", icon: PiUsers, label: "Utilisateurs", isUpdated: false, isActive: false, href: "/utilisateur", role: "ROLE_DSI", isNavbarItemDisabled: false},
                {key: "parameters", icon: PiGear, label: "Paramètres", isUpdated: false, isActive: false, role: "ROLE_ACCOUNTING", href: "/parametres-generaux", isNavbarItemDisabled: false},
            ],
            bottomMenu: {
                notifications : {icon: PiBell, label: "Notifications", isActive: false},
                logout: {icon: PiSignOut, label: "Déconnexion"},
                user: {image: "", label: `${loggedUser.firstName} ${loggedUser.lastName}`, href: "/mon-compte"}
            }
        };

        for(let index in initialMenu.topMenu) {
            if(initialMenu.topMenu[index].key === activeMenu) {
                initialMenu.topMenu[index].isActive = true;
            }

            if(initialMenu.topMenu[index].key === updatedMenu) {
                initialMenu.topMenu[index].isUpdated = true;
            }
        }

        if(hasNotification) {
            initialMenu.bottomMenu.notifications.isActive = true;
        }

        return initialMenu;
    }, [activeMenu, updatedMenu, hasNotification, loggedUser]);

    const [open, setOpen] = useState(false);
    const { isGranted } = useUserPermission()
    return (
        <Flex direction={["column", "row"]}>
            <Navbar {...layoutParameters}>
                <NavbarHeader open={open} setOpen={setOpen} />
                <NavbarBody
                    topMenu={
                        <NavbarList>
                            {layoutParameters.topMenu.map((menu: {icon?: IconType, label: string, isUpdated: boolean, isActive: boolean, isNavbarItemDisabled: boolean, href?: string, role?: string}) => {
                                    if (menu.role && !isGranted(menu.role)) {
                                        return null;
                                    }

                                    return (
                                        <Box key={menu.label}>
                                            <NavbarItem label={menu.label} isActive={menu.isActive} isNavbarItemDisabled={menu.isNavbarItemDisabled} href={menu.href}>
                                                <NavbarIcon
                                                    icon={menu.icon}
                                                    isUpdated={menu.isUpdated}
                                                />
                                            </NavbarItem>
                                        </Box>
                                    );
                                }
                            )}
                        </NavbarList>
                    }
                    bottomMenu={
                        <NavbarList>
                            <Box key={layoutParameters.bottomMenu.notifications.label}>
                                <NavbarItem label={layoutParameters.bottomMenu.notifications.label} isActive={layoutParameters.bottomMenu.notifications.isActive} isNavbarItemDisabled={true}>
                                    <NavbarIcon
                                        icon={layoutParameters.bottomMenu.notifications.icon}
                                        isUpdated={layoutParameters.bottomMenu.notifications.isActive}

                                    />
                                </NavbarItem>
                            </Box>
                            <Box key={layoutParameters.bottomMenu.logout.label}>
                                <NavbarItem label={layoutParameters.bottomMenu.logout.label} isActive={false} onClick={onLogOut} isNavbarItemDisabled={false}>
                                    <NavbarIcon
                                        icon={layoutParameters.bottomMenu.logout.icon}
                                        isUpdated={false}
                                    />
                                </NavbarItem>
                            </Box>
                            <Box key={layoutParameters.bottomMenu.user.label}>
                                <NavbarItem label={layoutParameters.bottomMenu.user.label} isActive={activeMenu === 'profile'} isNavbarItemDisabled={false} href={layoutParameters.bottomMenu.user.href}>
                                    <NavbarUserIcon name={layoutParameters.bottomMenu.user.label} image={layoutParameters.bottomMenu.user.image} />
                                </NavbarItem>
                            </Box>
                        </NavbarList>
                    }
                    open={open}
                />
            </Navbar>
            <DefaultLayout>
                {children}
            </DefaultLayout>
        </Flex>
    );
}
